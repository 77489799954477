import { graphql } from 'gatsby';

import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import parse from 'html-react-parser';

export const query = graphql`
  query AboutPage {
    strapiAbout {
      about {
        data {
          about
        }
      }
      contact {
        data {
          contact
        }
      }
    }
  }
`;

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <Seo title='Home' />
      <div className='page-about'>
        <div className='about'>{parse(data.strapiAbout.about.data.about)}</div>
        <div className='contacts'>
          {parse(data.strapiAbout.contact.data.contact)}
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
